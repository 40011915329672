body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    page: portrait;
  }
}

@page portrait {
  margin: 0;
  size: 1920px 2715px;
}

.mobile-show-accessibility .regular,
.show-accessibility .regular {
  color: #00a7db;
}

.regular {
  font-size: 1.25rem;
  font-weight: inherit;
}

.mobile-show-accessibility .large,
.show-accessibility .large {
  color: #fff;
}

.large {
  font-size: 1.625rem;
  font-weight: inherit;
}

.mobile-show-accessibility .extralarge,
.show-accessibility .extralarge {
  color: #fff;
}

.extralarge {
  font-size: 1.875rem;
  font-weight: inherit;
}

.mobile-show-accessibility span,
.show-accessibility span {
  text-decoration: none;
  padding: 0 0.125rem;
  align-items: baseline;
}

.mobile-show-accessibility > span,
.show-accessibility > span {
  height: 2.25rem;
  line-height: 1rem !important;
  padding: 0.625rem 0.625rem;
  white-space: nowrap !important;
  text-decoration: none;
}
